/* eslint-disable prefer-destructuring */
/* eslint-disable no-shadow */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOfflinemessage } from "./offlinemessages.slice";
import { externalappsService } from "../services";
import { AsyncState } from "../shared/models/interfaces/asyncstate.interface";
import { DropdownTeam } from "./teams.slice";
import { IUser } from "./users.slice";
import customToast from "../shared/utils/customToast";
import { IToastType } from "../shared/models/types/Toast.type";
import botAllowed from "../shared/utils/botAllowed";
import { FinishMessages } from "./settings.slice";

export enum ExternalAppType {
  WHATSAPP = "WHATSAPP",
  AGIDESK = "AGIDESK",
  BOTMAKER = "BOTMAKER",
  VTEX = "VTEX",
}

export enum ExternalappItemType {
  CHAT = "CHAT",
}

export interface IAppPublicServicesItems {
  label: string;
  key: string;
  endpoint: string;
  description: string[];
  url: string;
}

export interface IAgideskEntity {
  id: string;
  title: string;
}
export interface IAgideskTicketInfo {
  title: string;
  subject: string;
  includechat: boolean;
  internal: boolean;
  openingsolved: boolean;
}

export interface IFieldsAgidesk {
  name: string;
  slug: string;
  url: string;
  email: string;
  appkey: string;
  enablechat: boolean;
  displaytitlechat: string;
  enablelogin: boolean;
  displaytitlelogin: string;
  servicerestrict: boolean;
  ticketinfo: IAgideskTicketInfo;
  ticketfinishinfo: Omit<IAgideskTicketInfo, "subject">;
  ticketfinishinactiveinfo: Omit<IAgideskTicketInfo, "subject">;
  category: string[];
  topic: string[];
  service: IAgideskEntity[];
  validate: boolean;
  ticketfinish: boolean;
  ticketfinishinactive: boolean;
  ticketfinishservice: IAgideskEntity;
  ticketfinishinactiveservice: IAgideskEntity;
  ticketfinishteam: {
    team: any;
    service: IAgideskEntity;
  }[];
  ticketfinishinactiveteam: {
    team: any;
    service: IAgideskEntity;
  }[];
}

export interface IFieldsWhatsApp {
  name: string;
  displayphone: string;
  phone: string;
  key: string;
  verifytoken: string;
  wabaid: string;
  starttemplate?: string;
  header: string;
  body: string;
  button: string;
  validate: boolean;
  defaultteam: DropdownTeam[] | [];
  offlinemessage?: IOfflinemessage;
  finishmessages?: FinishMessages;
}

export interface IFieldsBotmaker {
  name: string;
  appkey: string;
  apiurl: string;
  botid: string;
  validate: boolean;
  active: boolean;
  defaultteam: DropdownTeam[] | [];
  finishmessages?: FinishMessages;
  externalphones:
    | {
        name: string;
        phone: string;
      }[]
    | [];
}

export interface IFieldsVtex {
  name: string;
  appkey: string;
  apptoken: string;
  accountid: string;
  validate: boolean;
  active: boolean;
}

export interface IExternalAppType {
  _id: ExternalAppType;
  title: string;
  icon: string | undefined;
  avatar: string | undefined;
  url: string;
}

export interface IExternalApp {
  _id?: string;
  tenant?: string;
  deleted?: boolean;
  created_at?: string;
  updated_at?: string;
  deleted_at?: string;
  created_by?: IUser | null;
  updated_by?: IUser | null;
  deleted_by?: IUser | null;
  active?: boolean;
  type: ExternalAppType;
}

export interface IExternalappItem {
  externalapp: IExternalApp;
  type: ExternalappItemType;
  origin: string;
  items: object[];
  updated_by?: IUser | null;
  created_by?: IUser | null;
  deleted_by?: IUser | null;
  deleted_at?: string;
  tenant: string;
  _id?: string;
}

export interface IAgideskUser {
  id: string;
  title: string;
  phone?: string;
  cellphone?: string;
  imported?: boolean;
  new?: boolean;
  email: string;
  admin: boolean;
  invite: boolean;
}
export interface IAgideskTeam {
  id: string;
  title: string;
  users: IAgideskUser[] | null;
  imported?: boolean;
}
export interface IExternalAppAgidesk extends IExternalApp {
  fields: IFieldsAgidesk;
  totalAgideskTeams: number;
  agideskTeams: IAgideskTeam[] | null;
}

export interface IExternalAppWhatsApp extends IExternalApp {
  fields: IFieldsWhatsApp;
}

export interface IExternalAppBotmaker extends IExternalApp {
  fields: IFieldsBotmaker;
}

export interface IExternalAppVtex extends IExternalApp {
  fields: IFieldsVtex;
}

export interface IAgideskTicket {
  id?: string | null;
  status: {
    title?: string | null;
    color?: string | null;
  };
  priority: {
    title?: string | null;
    color?: string | null;
  };
  searchid?: string | null;
  searchtitle?: string | null;
  title?: string | null;
  duedate?: string | null;
  updated_at?: string | null;
  metrics: {
    comments?: string | null;
  };
  responsible: {
    fulltitle?: string | null;
    avatar?: object;
  };
  team: {
    fulltitle?: string | null;
  };
  lastcomment: {
    author: {
      firstname: string | null;
    };
    privacy_id: string | null;
    content: string | null;
  };
  contact?: string | null;
  customer?: string | null;
  internal?: string | null;
}

// export interface IAgideskTicket {
//   id?: string | null;
//   status: {
//     title?: string | null;
//     color?: string | null;
//   };
//   priority: {
//     title?: string | null;
//     color?: string | null;
//   };
//   searchid?: string | null;
//   title?: string | null;
//   duedate?: string | null;
//   updated_at?: string | null;
//   metrics: {
//     comments?: string | null;
//   };
//   responsible: {
//     fulltitle?: string | null;
//     avatar?: object;
//   };
//   team: {
//     fulltitle?: string | null;
//   };
//   lastcomment: {
//     author: {
//       firstname: string | null;
//     };
//     privacy_id: string | null;
//     content: string | null;
//   };
//   contact?: string | null;
//   customer?: string | null;
// }

interface ExternalappsState extends AsyncState {
  isLoadingExternalapps: boolean;
  isRemoving: boolean;
  isCreatingTicket: boolean;
  isUpdatingTicket: boolean;
  selectedTypes: IExternalAppType[] | null;
  externalAppAgidesk: IExternalAppAgidesk[];
  selectedExternalAppAgidesk: IExternalAppAgidesk;
  totalExternalAppAgidesk: number;
  externalAppWhatsApp: IExternalAppWhatsApp[];
  selectedExternalAppWhatsApp: IExternalAppWhatsApp;
  totalExternalAppWhatsApp: number;
  agidesktickets: IAgideskTicket[];
  externalAppBotmaker: IExternalAppBotmaker[];
  selectedExternalAppBotmaker: IExternalAppBotmaker;
  totalExternalAppBotmaker: number;
  externalAppVtex: IExternalAppVtex[];
  selectedExternalAppVtex: IExternalAppVtex;
  totalExternalAppVtex: number;
}

export interface IFilterProps {
  type?: ExternalAppType;
  types?: ExternalAppType[];
  deleted?: boolean;
  "fields.name"?: string;
  active?: boolean;
  "fields.validate"?: boolean;
}

export interface IFilter {
  skip?: number;
  limit?: number;
  filter: IFilterProps;
}

export interface INewExternalapp {
  type: ExternalAppType;
  fields: {
    name: string;
  };
}

export interface IVtexFilter {
  skip: number;
  limit: number;
  filter: string;
  externalappid: string;
}
export interface IBotmakerFilter {
  skip: number;
  limit: number;
  filter: string;
  externalappid: string;
}
export interface IAgideskFilter {
  skip: number;
  limit: number;
  filter: string;
  externalappid: string;
  main?: boolean;
}

export interface IAgideskNewTicket {
  services: string;
  contacts: string;
  title: string;
  htmlcontent?: string;
  addchat?: boolean;
  chat: string;
  files?: string[];
  internal?: boolean;
  openingsolved?: boolean;
  messages?: string[];
}

export interface IAgideskUpdateTicket {
  tasks: string;
  tasktitle?: string;
  contacts: string;
  addchat?: boolean;
  htmlcontent: string;
  chat: string;
  files?: string[];
  internal?: boolean;
  openingsolved?: boolean;
  messages?: string[];
}

export interface IAgideskTicketFilter {
  id: string | undefined;
  contacts: string;
  chat: string;
  skip?: number;
  limit?: number;
  priority?: boolean;
  follower?: boolean;
  filter?: string;
}

const initialState: ExternalappsState = {
  isRemoving: false,
  isLoadingExternalapps: false,
  selectedTypes: null,
  totalExternalAppWhatsApp: 0,
  totalExternalAppAgidesk: 0,
  totalExternalAppBotmaker: 0,
  totalExternalAppVtex: 0,
  externalAppAgidesk: [],
  externalAppWhatsApp: [],
  externalAppBotmaker: [],
  externalAppVtex: [],
  selectedExternalAppAgidesk: {
    fields: {
      name: "",
      url: "",
      email: "",
      appkey: "",
      category: [],
      topic: [],
      service: [
        {
          id: "",
          title: "",
        },
      ],
      slug: "",
      validate: false,
      servicerestrict: false,
      enablechat: false,
      displaytitlechat: "",
      enablelogin: false,
      displaytitlelogin: "",
      ticketinfo: {
        title: "",
        subject: "",
        includechat: false,
        internal: false,
        openingsolved: false,
      },
      ticketfinishinfo: {
        title: "",
        includechat: false,
        internal: false,
        openingsolved: false,
      },
      ticketfinish: false,
      ticketfinishinactive: false,
      ticketfinishservice: { id: "", title: "" },
      ticketfinishteam: [{ team: "", service: { id: "", title: "" } }],
      ticketfinishinactiveinfo: {
        title: "",
        includechat: false,
        internal: false,
        openingsolved: false,
      },
      ticketfinishinactiveservice: { id: "", title: "" },
      ticketfinishinactiveteam: [{ team: "", service: { id: "", title: "" } }],
    },
    active: false,
    type: ExternalAppType.AGIDESK,
    agideskTeams: null,
    totalAgideskTeams: 0,
  },
  selectedExternalAppWhatsApp: {
    fields: {
      name: "",
      displayphone: "",
      phone: "",
      key: "",
      verifytoken: "",
      wabaid: "",
      starttemplate: "",
      header: "",
      body: "",
      button: "",
      validate: false,
      defaultteam: [],
      offlinemessage: undefined,
      finishmessages: {
        agent: "",
        contact: "",
        inactivity: "",
      },
    },
    active: false,
    type: ExternalAppType.WHATSAPP,
  },
  selectedExternalAppBotmaker: {
    fields: {
      name: "",
      appkey: "",
      apiurl: "",
      botid: "",
      active: true,
      validate: false,
      defaultteam: [],
      externalphones: [],
      finishmessages: {
        agent: "",
        contact: "",
        inactivity: "",
      },
    },
    active: false,
    type: ExternalAppType.BOTMAKER,
  },
  selectedExternalAppVtex: {
    fields: {
      name: "",
      appkey: "",
      apptoken: "",
      accountid: "",
      active: true,
      validate: false,
    },
    active: false,
    type: ExternalAppType.VTEX,
  },
  isSuccess: false,
  isError: false,
  isCreatingTicket: false,
  isUpdatingTicket: false,
  agidesktickets: [],
};

export const getSearchExternalApps = createAsyncThunk(
  "externalapps/search",
  async (filter: IFilter, thunkAPI) => {
    try {
      return await externalappsService.searchExternalApps(filter);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchWhatsAppExternalAppsToChat = createAsyncThunk(
  "externalapps/chats",
  async ({ teams }: { teams: string[] }, thunkAPI) => {
    try {
      return await externalappsService.searchWhatsAppExternalAppsToChat({
        teams,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getSearchExternalAppsToChat = createAsyncThunk(
  "externalapps/chats",
  async (
    { teams, types }: { teams: string[]; types: ExternalAppType[] },
    thunkAPI
  ) => {
    try {
      return await externalappsService.searchExternalAppsToChat({
        teams,
        types,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAgideskServices = createAsyncThunk(
  "externalapps/agideskservices",
  async (
    {
      _services,
    }: {
      _services: IAgideskFilter;
      _validate?: boolean;
      _validateError?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await externalappsService.searchAgideskServices(_services);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const searchAgideskTicket = createAsyncThunk(
  "externalapps/agidesklastticket",
  async ({ search }: { search: IAgideskTicketFilter }, thunkAPI) => {
    try {
      return await externalappsService.searchAgideskTicket(search);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createAgideskTicket = createAsyncThunk(
  "externalapps/agidesknewticket",
  async (
    { id, ticket }: { id: string | undefined; ticket: IAgideskNewTicket },
    thunkAPI
  ) => {
    try {
      return await externalappsService.createAgideskTicket({ id, ticket });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateAgideskTicket = createAsyncThunk(
  "externalapps/agideskupdateticket",
  async (
    { id, ticket }: { id: string | undefined; ticket: IAgideskUpdateTicket },
    thunkAPI
  ) => {
    try {
      return await externalappsService.updateAgideskTicket({ id, ticket });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAgideskTeams = createAsyncThunk(
  "externalapps/agideskteams",
  async ({ _teams }: { _teams: IAgideskFilter }, thunkAPI) => {
    try {
      return await externalappsService.searchAgideskTeams(_teams);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getAgideskTeamUsers = createAsyncThunk(
  "externalapps/agideskteamusers",
  async (
    { teamid, externalappid }: { teamid: string; externalappid: string },
    thunkAPI
  ) => {
    try {
      return await externalappsService.searchAgideskTeamUsers({
        teamid,
        externalappid,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const agideskImport = createAsyncThunk(
  "externalapps/agideskimport",
  async (
    { data, externalappid }: { data: IAgideskTeam[]; externalappid: string },
    thunkAPI
  ) => {
    try {
      return await externalappsService.agideskImport({
        data,
        externalappid,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getBotmakerChannels = createAsyncThunk(
  "externalapps/botmakerchannels",
  async (
    {
      _channels,
    }: {
      _channels: IBotmakerFilter;
      _validate?: boolean;
      _validateError?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await externalappsService.searchBotmakerChannels(_channels);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const validateVtexAPI = createAsyncThunk(
  "externalapps/validatevtexAPI",
  async (
    {
      _params,
    }: { _params: IVtexFilter; _validate?: boolean; _validateError?: boolean },
    thunkAPI
  ) => {
    try {
      return await externalappsService.validateVtexAPI(_params);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectExternalapp = createAsyncThunk(
  "externalapps/select",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      return await externalappsService.externalapps({ _id });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const selectAgideskApp = createAsyncThunk(
  "externalapps/select",
  async ({ _id }: { _id: string }, thunkAPI) => {
    try {
      const agideskApp = await externalappsService.externalapps({ _id });
      const teams = await externalappsService.searchAgideskTeams({
        skip: 0,
        filter: "",
        externalappid: _id,
      });

      agideskApp.totalAgideskTeams =
        typeof teams.count !== "undefined" ? teams.count : 0;
      agideskApp.agideskTeams =
        typeof teams.results !== "undefined" ? teams.results : {};

      return agideskApp;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const deleteExternalapps = createAsyncThunk(
  "externalapps/delete",
  async (ids: string[], thunkAPI) => {
    try {
      return await externalappsService.deleteExternalApps({ ids });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const createExternalapps = createAsyncThunk(
  "externalapps/create",
  async (_externalapp: INewExternalapp, thunkAPI) => {
    try {
      return await externalappsService.createExternalApps({ _externalapp });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const updateExternalapps = createAsyncThunk(
  "externalapps/update",
  async (
    {
      _externalapp,
      _validate,
    }: {
      _externalapp: any;
      _validate?: boolean;
    },
    thunkAPI
  ) => {
    try {
      return await externalappsService.update({ _externalapp });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const getTypes = createAsyncThunk(
  "externalapps/types",
  async (_, thunkAPI) => {
    try {
      return await externalappsService.types();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

export const externalappsSlice = createSlice({
  name: "externalapps",
  initialState,
  reducers: {
    logout() {
      return initialState;
    },
    reset: (state) => {
      state.isSuccess = false;
      state.isError = false;
      state.isLoadingExternalapps = false;
    },
    selectExternalappsAgidesk(
      state,
      action: PayloadAction<IExternalAppAgidesk[]>
    ) {
      state.externalAppAgidesk = action.payload || [];
    },
    selectTotalExternalappsAgidesk(state, action: PayloadAction<number>) {
      state.totalExternalAppAgidesk = action.payload || 0;
    },
    selectExternalappsWhatsApp(
      state,
      action: PayloadAction<IExternalAppWhatsApp[]>
    ) {
      state.externalAppWhatsApp = action.payload || [];
    },
    selectTotalExternalappsWhatsApp(state, action: PayloadAction<number>) {
      state.totalExternalAppWhatsApp = action.payload || 0;
    },
    selectExternalappsBotmaker(
      state,
      action: PayloadAction<IExternalAppBotmaker[]>
    ) {
      state.externalAppBotmaker = action.payload || [];
    },
    selectTotalExternalappsBotmaker(state, action: PayloadAction<number>) {
      state.totalExternalAppBotmaker = action.payload || 0;
    },
    selectExternalappsVtex(state, action: PayloadAction<IExternalAppVtex[]>) {
      state.externalAppVtex = action.payload || [];
    },
    selectTotalExternalappsVtex(state, action: PayloadAction<number>) {
      state.totalExternalAppVtex = action.payload || 0;
    },
    ticketIsCreating(state, action: PayloadAction<boolean>) {
      state.isCreatingTicket = action.payload;
    },
    ticketIsUpdating(state, action: PayloadAction<boolean>) {
      state.isUpdatingTicket = action.payload;
    },
    reselectExternalappTypes(
      state,
      action: PayloadAction<IExternalAppType[] | [] | null>
    ) {
      state.selectedTypes = action.payload;
    },
    reselectExternalappAgidesk(
      state,
      action: PayloadAction<IExternalAppAgidesk>
    ) {
      state.selectedExternalAppAgidesk = action.payload;
    },
    reselectExternalappWhatsApp(
      state,
      action: PayloadAction<IExternalAppWhatsApp>
    ) {
      state.selectedExternalAppWhatsApp = action.payload;
    },
    reselectExternalappBotmaker(
      state,
      action: PayloadAction<IExternalAppBotmaker>
    ) {
      state.selectedExternalAppBotmaker = action.payload;
    },
    reselectExternalappVtex(state, action: PayloadAction<IExternalAppVtex>) {
      state.selectedExternalAppVtex = action.payload;
    },
    refreshLogout() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTypes.pending, (state) => {
        state.isLoadingExternalapps = true;
      })
      .addCase(getTypes.fulfilled, (state, action) => {
        if (action.payload !== null && action.payload.length > 0) {
          const _types: any = [];
          action.payload.forEach((_type: ExternalAppType) => {
            if (_type === ExternalAppType.AGIDESK) {
              _types.push({
                _id: _type,
                icon: "las la-life-ring",
                avatar: undefined,
                title: "Agidesk",
                url: "/settings/integration/agidesk",
              });
            } else if (_type === ExternalAppType.WHATSAPP) {
              _types.push({
                _id: _type,
                icon: "lab la-whatsapp",
                avatar: undefined,
                title: "WhatsApp",
                url: "/settings/integration/whatsapp",
              });
            } else if (_type === ExternalAppType.BOTMAKER && botAllowed()) {
              _types.push({
                _id: _type,
                icon: "las la-robot",
                avatar: undefined,
                title: "Agibot",
                url: "/settings/integration/agibot",
              });
            } else if (_type === ExternalAppType.VTEX && botAllowed()) {
              _types.push({
                _id: _type,
                icon: "las la-shopping-cart",
                avatar: undefined,
                title: "VTEX",
                url: "/settings/integration/vtex",
              });
            }
          });
          state.isSuccess = true;
          state.isError = false;
          state.selectedTypes = _types;
        } else {
          state.isError = true;
          state.selectedTypes = null;
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(getBotmakerChannels.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          action?.payload !== null &&
          typeof action?.payload?.error === "undefined"
        ) {
          if (action.meta.arg._validate && !action.meta.arg._validateError) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Validado com sucesso!`,
            });
          }
        } else if (action.meta.arg._validate) {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado na validação!`,
          });
        }
      })
      .addCase(validateVtexAPI.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          action?.payload !== null &&
          typeof action?.payload?.error === "undefined"
        ) {
          if (action.meta.arg._validate && !action.meta.arg._validateError) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Validado com sucesso!`,
            });
          }
        } else if (action.meta.arg._validate) {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado na validação!`,
          });
        }
      })
      .addCase(getTypes.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(getAgideskServices.pending, () => {
        //
      })
      .addCase(getAgideskServices.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (
          action?.payload !== null &&
          typeof action?.payload?.error === "undefined"
        ) {
          if (action.meta.arg._validate && !action.meta.arg._validateError) {
            customToast({
              type: IToastType.SUCCESS,
              message: `Validado com sucesso!`,
            });
          }
        } else if (action.meta.arg._validate) {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado na validação!`,
          });
        }
      })
      .addCase(getAgideskServices.rejected, (state) => {
        state.isError = true;
      })
      .addCase(agideskImport.pending, () => {
        // state.isLoadingMessages = true;
      })
      .addCase(agideskImport.fulfilled, (state, action) => {
        if (!action.payload.error) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: "Importação realizada com sucesso!",
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: "A importação falhou! Tente novamente!",
          });
        }
      })
      .addCase(agideskImport.rejected, (state) => {
        state.isError = true;
      })
      .addCase(getAgideskTeams.pending, () => {
        // state.isLoadingMessages = true;
      })
      .addCase(getAgideskTeams.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (!action.payload.error) {
          state.selectedExternalAppAgidesk.agideskTeams =
            action.payload.results;
          state.selectedExternalAppAgidesk.totalAgideskTeams =
            action.payload.count;
        }
      })
      .addCase(getAgideskTeams.rejected, (state) => {
        state.isError = true;
      })
      .addCase(getAgideskTeamUsers.pending, () => {
        // state.isLoadingMessages = true;
      })
      .addCase(getAgideskTeamUsers.fulfilled, (state, action) => {
        state.isSuccess = true;
        try {
          if (!action.payload.error) {
            const teamid = action.meta.arg.teamid;
            const externalappid = action.meta.arg.externalappid;
            let _stateAll = state.externalAppAgidesk;
            let _state = state.selectedExternalAppAgidesk.agideskTeams;
            if (_state) {
              _state = _state.map((_team) => {
                if (_team.id === teamid) {
                  _team.users = action.payload.results;
                }
                return _team;
              });
            }
            _stateAll = _stateAll.map((state) => {
              if (state._id === externalappid) {
                state.agideskTeams = _state;
              }
              return state;
            });
            state.selectedExternalAppAgidesk.agideskTeams = _state;
            state.externalAppAgidesk = _stateAll;
          }
        } catch (error) {
          // console.log(error);
        }
      })
      .addCase(getAgideskTeamUsers.rejected, (state) => {
        state.isError = true;
      })
      .addCase(deleteExternalapps.pending, (state) => {
        state.isRemoving = true;
      })
      .addCase(deleteExternalapps.fulfilled, (state, action) => {
        if (
          action.payload !== null &&
          typeof action.payload !== "undefined" &&
          typeof action.payload?.message !== "string"
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Remoção realizada com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado!`,
          });
        }
        state.isRemoving = false;
      })
      .addCase(deleteExternalapps.rejected, (state) => {
        state.isError = true;
        state.isRemoving = false;
      })
      .addCase(getSearchExternalAppsToChat.pending, (state) => {
        state.isLoadingExternalapps = true;
      })
      .addCase(getSearchExternalAppsToChat.fulfilled, (state, action) => {
        if (
          typeof action.payload !== "undefined" &&
          action.payload &&
          action.payload.length > 0
        ) {
          state.isSuccess = true;
          state.isError = false;
          const _type = action.payload[0].type;

          if (_type === ExternalAppType.WHATSAPP) {
            state.externalAppWhatsApp = action.payload;
            state.totalExternalAppWhatsApp = action.payload.length;
          }

          if (_type === ExternalAppType.BOTMAKER) {
            state.externalAppBotmaker = action.payload;
            state.totalExternalAppBotmaker = action.payload.length;
          }
        } else {
          state.isError = true;
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(getSearchExternalAppsToChat.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(getSearchExternalApps.pending, (state, action) => {
        if (action.meta.arg.limit === 10) {
          state.isLoadingExternalapps = true;
        }
      })
      .addCase(getSearchExternalApps.fulfilled, (state, action) => {
        if (
          typeof action.payload !== "undefined" &&
          action.payload?.results &&
          action.payload.results.length > 0
        ) {
          state.isSuccess = true;
          state.isError = false;
          if (action.meta.arg.filter.type === ExternalAppType.AGIDESK) {
            state.externalAppAgidesk = action.payload.results;
            state.totalExternalAppAgidesk = action.payload.count;
          } else if (action.meta.arg.filter.type === ExternalAppType.WHATSAPP) {
            state.externalAppWhatsApp = action.payload.results;
            state.totalExternalAppWhatsApp = action.payload.count;
          } else if (action.meta.arg.filter.type === ExternalAppType.BOTMAKER) {
            state.externalAppBotmaker = action.payload.results;
            state.totalExternalAppBotmaker = action.payload.count;
          } else if (action.meta.arg.filter.type === ExternalAppType.VTEX) {
            state.externalAppVtex = action.payload.results;
            state.totalExternalAppVtex = action.payload.count;
          }
        } else {
          state.isError = true;
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(getSearchExternalApps.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(createExternalapps.pending, (state) => {
        state.isLoadingExternalapps = true;
      })
      .addCase(createExternalapps.fulfilled, (state, action) => {
        if (
          action.payload &&
          typeof action.payload !== "undefined" &&
          !action.payload?.error
        ) {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: "Integração criada com sucesso!",
          });
          state.isError = false;
          if (action.payload.type === ExternalAppType.AGIDESK) {
            state.selectedExternalAppAgidesk = action.payload;
          } else if (action.payload.type === ExternalAppType.WHATSAPP) {
            state.selectedExternalAppWhatsApp = action.payload;
          } else if (action.payload.type === ExternalAppType.BOTMAKER) {
            state.selectedExternalAppBotmaker = action.payload;
          } else if (action.payload.type === ExternalAppType.VTEX) {
            state.selectedExternalAppVtex = action.payload;
          }
        } else {
          customToast({
            type: IToastType.ERROR,
            message: `Algo deu errado! Tente novamente.`,
          });
          state.isError = true;
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(createExternalapps.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(updateExternalapps.pending, () => {
        // state.isLoadingExternalapps = true;
      })
      .addCase(updateExternalapps.fulfilled, (state, action) => {
        if (!action.meta.arg._validate) {
          if (
            action?.payload !== null &&
            typeof action?.payload?._id !== "undefined"
          ) {
            state.isSuccess = true;
            customToast({
              type: IToastType.SUCCESS,
              message: `Alteração salva com sucesso!`,
            });
          } else {
            customToast({
              type: IToastType.ERROR,
              message: `Algo deu errado!`,
            });
            state.isError = true;
          }
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(updateExternalapps.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(selectExternalapp.pending, (state) => {
        state.isLoadingExternalapps = true;
      })
      .addCase(selectExternalapp.fulfilled, (state, action) => {
        state.isSuccess = true;
        if (action.payload.type === ExternalAppType.AGIDESK) {
          state.selectedExternalAppAgidesk = action.payload || null;
        } else if (action.payload.type === ExternalAppType.BOTMAKER) {
          state.selectedExternalAppBotmaker = action.payload || null;
        } else if (action.payload.type === ExternalAppType.VTEX) {
          state.selectedExternalAppVtex = action.payload || null;
        } else {
          state.selectedExternalAppWhatsApp = action.payload || null;
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(selectExternalapp.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(searchAgideskTicket.pending, (state) => {
        state.isLoadingExternalapps = true;
      })
      .addCase(searchAgideskTicket.fulfilled, (state, action) => {
        if (action?.payload && typeof action.payload._id !== "undefined") {
          state.isSuccess = true;
          if (action.payload.length > 0) {
            state.agidesktickets = action.payload;
          }
        }
        state.isLoadingExternalapps = false;
      })
      .addCase(searchAgideskTicket.rejected, (state) => {
        state.isError = true;
        state.isLoadingExternalapps = false;
      })
      .addCase(createAgideskTicket.pending, (state) => {
        state.isLoadingExternalapps = true;
        state.isCreatingTicket = true;
      })
      .addCase(createAgideskTicket.fulfilled, (state, action) => {
        if (action?.payload && typeof action.payload._id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Atendimento ATD-${
              action.payload.items[action.payload.items.length - 1].id
            } gerado com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: "Falha na criação do atendimento! Tente novamente!",
          });
        }
        state.isLoadingExternalapps = false;
        state.isCreatingTicket = false;
      })
      .addCase(createAgideskTicket.rejected, (state) => {
        state.isError = true;
        state.isCreatingTicket = false;
        state.isLoadingExternalapps = false;
      })
      .addCase(updateAgideskTicket.pending, (state) => {
        state.isLoadingExternalapps = true;
        state.isUpdatingTicket = true;
      })
      .addCase(updateAgideskTicket.fulfilled, (state, action) => {
        if (action?.payload && typeof action?.payload?.id !== "undefined") {
          state.isSuccess = true;
          customToast({
            type: IToastType.SUCCESS,
            message: `Atendimento ATD-${action.payload.id} atualizado com sucesso!`,
          });
        } else {
          state.isError = true;
          customToast({
            type: IToastType.ERROR,
            message: "Falha na atualização do atendimento! Tente novamente!",
          });
        }
        state.isLoadingExternalapps = false;
        state.isUpdatingTicket = false;
      })
      .addCase(updateAgideskTicket.rejected, (state) => {
        state.isError = true;
        state.isUpdatingTicket = false;
        state.isLoadingExternalapps = false;
      });
  },
});

export const {
  logout,
  reset,
  refreshLogout,
  reselectExternalappTypes,
  ticketIsCreating,
  ticketIsUpdating,
  reselectExternalappAgidesk,
  reselectExternalappWhatsApp,
  reselectExternalappBotmaker,
  reselectExternalappVtex,
  selectExternalappsAgidesk,
  selectExternalappsBotmaker,
  selectExternalappsVtex,
  selectExternalappsWhatsApp,
  selectTotalExternalappsAgidesk,
  selectTotalExternalappsBotmaker,
  selectTotalExternalappsWhatsApp,
  selectTotalExternalappsVtex,
} = externalappsSlice.actions;
export default externalappsSlice.reducer;
