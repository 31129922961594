import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../hooks/redux/hooks";
import {
  getSettings,
  reselectSettings,
  updateSettings,
} from "../slices/settings.slice";
import LoadingDots from "../shared/components/LoadingDots.component";
import FooterPageInfo from "../shared/components/FooterPageInfo.component";
import HeaderPageInfo from "../shared/components/HeaderPageInfo.component";
import DividerInfoPages from "../shared/components/DividerInfoPages.component";
import RelationModal from "../shared/components/Modals/RelationModal.component";
import Section from "../shared/components/Section.component";
import SectionElement from "../shared/components/SectionElement.component";
import { userdata } from "../slices/auth.slice";
import AppkeySection from "../features/settings/Appkey.section";
import LgpdSection from "../features/settings/Lgpd.section";
import Badge from "../shared/components/Badge.component";
import FinishMessagesSection from "../features/settings/FinishMessages.section";

const GeneralPage = ({
  disable,
  setDisable,
  toggleSidebar,
}: {
  toggleSidebar: boolean;
  disable: boolean;
  setDisable: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const { isLoadingSettings, selectedSettings } = useAppSelector(
    (state) => state.settings
  );

  const [, setReloadForced] = useState<boolean>(true);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [toggle, setToggle] = useState<boolean>(
    typeof selectedSettings?.offlinemessage?._id !== "undefined"
  );

  useEffect(() => {
    (async () => {
      if (
        typeof location.hash !== "undefined" &&
        !["#settings-defaultagent", "#settings-defaultteam"].includes(
          location.hash
        )
      ) {
        const { payload } = await dispatch(getSettings({}));
        setReloadForced(false);
        if (typeof payload?.offlinemessage?._id !== "undefined") {
          setToggle(true);
        }
      }
    })();
  }, []);

  useEffect(() => {
    const handleClickScrollDefaultagent = () => {
      const element = document.getElementById("settings-defaultagent");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollDefaultteam = () => {
      const element = document.getElementById("settings-defaultteam");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollOfficehour = () => {
      const element = document.getElementById("settings-officehour");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    const handleClickScrollOfflinemessage = () => {
      const element = document.getElementById("settings-offlinemessage");
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
    switch (location.hash) {
      case "#settings-defaultagent":
        handleClickScrollDefaultagent();
        break;
      case "#settings-defaultteam":
        handleClickScrollDefaultteam();
        break;
      case "#settings-officehour":
        handleClickScrollOfficehour();
        break;
      case "#settings-offlinemessage":
        handleClickScrollOfflinemessage();
        break;
      default:
        break;
    }
  }, [location]);

  const goBack = () => {
    navigate(-1);
  };

  const goSave = async () => {
    setDisable(true);
    const { payload } = await dispatch(
      updateSettings({
        _settings: {
          ...selectedSettings,
          survey: selectedSettings?.survey,
          sendsurvey: selectedSettings?.sendsurvey,
          defaultbot: {
            ...selectedSettings?.defaultbot,
            avatar: selectedSettings?.defaultbot?.avatar?._id,
          },
          company: {
            ...selectedSettings?.company,
            avatar: selectedSettings?.company?.avatar?._id,
          },
          lgpd: selectedSettings?.lgpd,
          inactivetime: selectedSettings?.inactivetime,
          officehour: selectedSettings?.officehour?._id,
          offlinemessage: selectedSettings?.offlinemessage?._id,
          defaultagent: selectedSettings?.defaultagent?._id,
          finishmessages: selectedSettings?.finishmessages,
          defaultteam: selectedSettings?.defaultteam?.map((_t) => {
            if (typeof _t === "string") return _t;
            return _t._id;
          }),
        },
      })
    );
    if (payload) {
      dispatch(
        reselectSettings({
          ...selectedSettings,
          updated_at: payload.updated_at,
          updated_by: payload.updated_by,
        })
      );
      dispatch(userdata());
    }
    setDisable(false);
  };

  if (isLoadingSettings)
    return (
      <LoadingDots
        className="flex justify-center items-center absolute top-0 bottom-0 right-0 left-0 h-screen"
        large
      />
    );
  return (
    <div className="container items-center my-auto mx-auto lg:px-44 mt-10 py-4 md:px-12 sm:px-8">
      <HeaderPageInfo
        created={selectedSettings?.created_at}
        updated={selectedSettings?.updated_at}
        deleted={selectedSettings?.deleted_at}
        title="Configurações gerais"
        imported={false}
        icon="las la-cog"
      />
      <Section
        icon="las la-info-circle"
        title="Informações da sua empresa"
        description="Edite as informações exibidas"
        component={
          <SectionElement
            instance={selectedSettings?.company}
            type="settings-company"
          />
        }
      />
      {typeof selectedSettings?.defaultagent?._id !== "undefined" ? (
        <>
          <DividerInfoPages />
          <Section
            icon="las la-laptop-code"
            title="Chaves de acesso"
            description="Gerencie as chaves de acesso"
            component={<AppkeySection instance={selectedSettings} />}
          />
        </>
      ) : null}
      <DividerInfoPages />
      <Section
        id="settings-officehour"
        icon="las la-calendar-check"
        title="Horário de atendimento"
        description="Selecione o horário padrão do sistema. Equipes sem horário de atendimento definido respeitarão este horário."
        component={
          <SectionElement
            instance={selectedSettings?.officehour}
            type="settings-officehour"
          />
        }
      />
      <DividerInfoPages />
      <Section
        id="settings-survey"
        icon="las la-smile"
        title="Pesquisa de satisfação"
        description="Selecione a pesquisa de satisfação padrão do sistema. Equipes sem pesquisa de satisfação definida enviarão esta pesquisa ao final dos atendimentos."
        component={
          <SectionElement
            instance={selectedSettings?.survey}
            type="settings-survey"
          />
        }
      />
      <DividerInfoPages />
      <Section
        id="settings-defaultteam"
        icon="las la-user-friends"
        title="Equipes disponíveis para atendimento"
        description="Selecione as equipes que atenderão as conversas pelo widget"
        component={
          <SectionElement
            instance={selectedSettings}
            type="settings-defaultteam"
            setShowModal={setShowModal}
          />
        }
      />
      <DividerInfoPages />
      <Section
        id="settings-offlinemessage"
        icon="las la-hourglass-end"
        title="Atendimentos fora de horário"
        // description={`Habilite o atendimento fora de horário.\nSelecione a mensagem que será enviada ao contato que tentar acessar fora do horário de atendimento.\nSerá registrada a tentativa através dos e-mails cadastrados na mensagem escolhida.`}
        description={`Habilite o atendimento fora de horário e selecione a configuração de perfil de atendimento fora do horário.\n
          Essa configuração definirá a mensagem a ser enviada ao contato ao solicitar um atendimento fora do horário e notificará os e-mails cadastrados.`}
        component={
          <SectionElement
            instance={selectedSettings?.offlinemessage}
            type="settings-offlinemessage"
            toggle={toggle}
            setToggle={setToggle}
          />
        }
      />
      <DividerInfoPages />
      <Section
        icon="las la-robot"
        title="Agente de atendimento padrão"
        description="Quem irá iniciar o atendimento dos seus contatos"
        component={
          <SectionElement
            instance={selectedSettings?.defaultbot}
            type="settings-defaultbot"
          />
        }
      />
      <DividerInfoPages />
      <Section
        id="settings-defaultagent"
        icon="las la-user-secret"
        title="Usuário padrão"
        description="Usuário utilizado pelo sistema para executar ações quando não houver um agente atribuído"
        component={
          <SectionElement
            instance={selectedSettings?.defaultagent}
            type="settings-defaultagent"
          />
        }
      />
      <DividerInfoPages />
      <Section
        icon="las la-flag-checkered"
        title="Mensagens de encerramento de conversa"
        // description="Personalize as mensagens de encerramento pelo agente, pelo contato e por inatividade"
        description={`Personalize as mensagens de encerramento pelo agente e por inatividade.\n
        <b>Atenção</b>: As mensagens configuradas aqui serão substituídas caso hajam mensagens de encerramento configuradas em suas integrações.\n
        Consulte suas integrações <b><a class="externalapplink" href="/settings/integration/whatsapp">WhatsApp</a></b> e <b><a class="externalapplink" href="/settings/integration/agibot">Agibot</a></b>.`}
        component={<FinishMessagesSection type="settings" />}
      />
      <DividerInfoPages />
      <Section
        icon="las la-clock"
        title="Controle de encerramento por inatividade"
        description="Definir qual o tempo limite de inatividade de um contato antes de encerrar a conversa"
        component={
          <SectionElement
            instance={selectedSettings?.inactivetime}
            type="settings-inactivetime"
          />
        }
      />
      <DividerInfoPages />
      <Section
        icon="las la-calendar-times"
        title="Programação de exclusão de conversas"
        description="Defina um tempo máximo de armazenamento de conversas pela empresa em acordo com a LGPD. <br/> Atenção: Caso algum time tenha uma regra de exclusão específica, ela será priorizada em relação a regra de exclusão geral."
        // children={<Badge classname="mt-2" label="Novo!" />}
        tableStyles="h-min"
        component={
          <SectionElement
            instance={selectedSettings}
            type="settings-programed-excluding"
          />
        }
      />
      <DividerInfoPages />
      <Section
        id="settings-lgpd"
        icon="las la-shield-alt"
        title="LGPD e coleta de dados"
        description="Defina o comportamento de campos que podem conter informações sensíveis"
        component={<LgpdSection />}
      />
      {typeof selectedSettings?.defaultagent?._id !== "undefined" ? (
        <>
          <DividerInfoPages />
          <Section
            icon="las la-comment-dots"
            title="Widgets"
            description="Incorpore nossa solução em seus sistemas externos e websites"
            component={
              <SectionElement
                instance={selectedSettings?.widget}
                type="settings-widget"
              />
            }
          />
        </>
      ) : null}
      <div className="mb-20" />
      <FooterPageInfo
        disabled={
          (typeof selectedSettings?.sendsurvey !== "undefined" &&
            selectedSettings.sendsurvey &&
            typeof selectedSettings.survey?._id === "undefined") ||
          typeof selectedSettings?.defaultagent?._id === "undefined" ||
          typeof selectedSettings?.inactivetime === "undefined" ||
          selectedSettings?.inactivetime.length === 0 ||
          disable
        }
        toggleSidebar={toggleSidebar}
        back={goBack}
        remove={goBack}
        save={goSave}
      />
      {/* MODALS */}
      {showModal ? (
        <RelationModal
          title="Relacionar equipes"
          icon="las la-user-friends"
          setShowModal={setShowModal}
        />
      ) : null}
    </div>
  );
};

export default GeneralPage;
