/* eslint-disable react/require-default-props */
import React from "react";
import { twMerge } from "tailwind-merge";

interface CustomCollapsibleProps {
  label: string;
  classname?: string;
  icon?: string;
  background?: string;
  title?: string;
}

const Badge = ({
  title,
  label,
  classname,
  icon,
  background = "bg-orange-500",
}: CustomCollapsibleProps) => {
  return (
    <div
      title={title ?? label}
      className={twMerge(
        "w-full px-2 py-[3px] flex items-center justify-center rounded-full text-white text-[14px]",
        background,
        classname
      )}
    >
      <div className="flex justify-center items-center">
        {icon && <i className={twMerge(icon, "mr-1")} />}
        <p>{label}</p>
      </div>
    </div>
  );
};

export default Badge;
